.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root{
    height: 30px;
    padding: 0 !important;
}
.Add_icon{
    width: 30px !important;
    height: 30px !important;
    color:#129547;
    cursor: pointer;
   
}
.actioinIcon{
    margin-right: 10px;
}
.handleClose{
    font-size: 14px;
}
.add_text{
    margin: 0 5px !important;
    font-size: 13px !important;
    font-weight: 700 !important;
}
.tableHeader{
    background-color: #F6F9FC;
}
.tableHead{
    font-size: 13px !important;
    color: #979899 !important ;
    font-weight: 700 !important;
    text-align: center !important;
}
.titleName{
    font-size: 28px !important;
    color:#129547;
    text-align: center;
    font-weight: 700 !important;
}
.closeButton{
    background-color: #129547 !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
}
.modalPOpup .MuiDialog-paper{
    border-radius: 30px !important;
}
.contentModal{
    padding: 20px 50px !important;
}
td{
   max-width:180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}
.snackBar{
    background-color: #129547 !important;
    color: white !important;
}
.snackBar svg{
    color: white !important;
}
.snackError{
    background-color: #E11F26 !important;
    color: white !important;
}
.snackError svg{
    color: white !important;
}
.titleModal{
    position: relative;
}
.closeIconPosition{
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
}
tr{
    height: 10% !important;
}
td{
    height: 10% !important;
}
th{
    height: 10% !important;
}
