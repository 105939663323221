.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #129547 !important;
  color: #ffffff;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover {
  background-color: #129547 !important;
  color: #ffffff;
  border: 1px solid #ffffff !important;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  border: 1px solid #979899 !important;
}
