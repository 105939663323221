
.Item_text{
    padding-left: 1.5rem;
    color: #979899;
}
.active p{
    color:black;
}
/* .active img{
    
} */
.nav_hover:hover{
    text-decoration: none;
}
.nav_hover.active p{
    color:black;
}
/* .nav_hover p{
    white-space: nowrap;
} */
.nav_hover.active .svg_nav{
    box-shadow: -5px 5px 10px rgba(217, 219, 221, 0.2), 5px -5px 10px rgba(217, 219, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(217, 219, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(217, 219, 221, 0.5);
    color:red;
    background-color: #F1F3F6;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    padding: 12px;
}
.nav_hover .svg_nav{
    width: 50px;
    height: 50px;
    padding: 12px;
}


.nav_hover.active path  {
  fill: #E11F26;
   
}
.nav_hover .navPadding {
   padding: 4px 8px !important;
}
