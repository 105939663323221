td{
   text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}
.selectOptions{
    font-size: 14px !important;
    margin: 4px 12px 4px 0px !important;
    padding:5px;
    height: 40px;
    /* background: #F1F3F6; */
    box-shadow: inset 0px 4px 4px rgb(0 0 0 / 25%), inset 0px -4px 4px #ffffff;
    border-radius: 5px !important;
}
label.MuiFormLabel-root.MuiFormLabel-colorPrimary.css-u4tvz2-MuiFormLabel-root {
    color: black;
}

.formLabel{
    color: black !important;
}
.address_field fieldSet{ 
    border: 0 !important;
    box-shadow: inset 0px -4px 4px #ffffff, inset 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 5px;
}
.addressField fieldSet{ 
    border: 0 !important;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px -4px 4px #ffffff;
    border-radius: 5px;
}
.locationIcon{
    color:#E11F26;
    background: #F1F3F6;
    box-shadow: -5px 5px 10px rgba(217, 219, 221, 0.2), 5px -5px 10px rgba(217, 219, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(217, 219, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(217, 219, 221, 0.5);
    border-radius: 100px;
    width: 32px !important;
    height: 32px !important;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.mapHeight{
    height: 350px;
}
.confirmLocationbtn{
    background-color: #129547 !important;
    color: white !important;
    text-transform: capitalize !important;
    padding: 10px 20px !important;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }
  .sharpicon{
    font-size: 20px !important;
    color: #129547;
    float: right;
  }
  