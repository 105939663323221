.userHeaderBox{
    background-color: #22272B;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.aboutLink{
    border-radius: 3px;
    padding: 8px 10px;
    white-space: nowrap;
    color:white;
    text-align: center;
}

.homeLink {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 10px;
    white-space: nowrap;
    color: #E11F26;
}

.staticLink {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 8px 10px;
    white-space: nowrap;
    color: #E11F26;
    width: 100px;
    text-align: center;
} 

.login_text {
    color:#E11F26;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
}

.userNameWidth {
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.customerNameWidth {
    color: white;
    min-width: 50px;
    max-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.uploadbtn {
    background: #22272B;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
    color:white;
    border-radius: 3px;
    font-size: 12px !important;
    text-align: center;
    padding: 8px 12px;
    cursor: pointer;
}
.adminUploadbtn {
    background: #129547 !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
    color:white;
    border-radius: 3px;
    font-size: 12px !important;
    text-align: center;
    padding: 8px 12px;
    cursor: pointer;
}

.footer_bg {
  background-color: #22272B;
  color:white;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.25);
  height: 37vh;
}

.footerList .MuiListItemText-primary {
    font-size: 12px;
}

.textWhite{
    color: white;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 20px;
}

.textWhite1{
    color: white;
    font-size: 12px;
    padding: 0 25%;
    text-align: center;
}
.hospital_card{
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 10px;
    width:214px;
    height: 122px;
    padding-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content:center ;
}
.hospitalLogo{
    margin: auto;
    display: block;
    width: 140px !important;
    height:100px !important;
}
.locationBorder{
    border-right: 2px solid white;
    margin: 0px 20px;
    height: 30px;
}
.locationColor{
    color: white;
    cursor: pointer;
}
.locationColor:hover{
    border-bottom:2px solid white
}
.toLabel{
    background: #F1F3F6;
    box-shadow: inset 0px -4px 4px #FFFFFF, inset 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.ambulanceImg{
    margin: auto;
    display: block;
}
.ambulanceName{
    font-size: 14px !important;
    color: #b1a8a8 !important;
    text-align: center;
    margin-top: 10px;
}
.ambulanceNameActive{
    color:white !important;
    font-weight: 700;
}
.ambulanceShadow{
    padding: 5px;
    cursor: pointer;
}
.ambulanceShadow:hover{
    background: #22272B;
    box-shadow: 
  0px 0px 7.5px 5px rgba(94, 91, 91, 0.25),
  0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset;

    border-radius: 5px;
    padding: 5px;
}
.ambulanceActive{
    background: #22272B;
    box-shadow: 
  0px 0px 7.5px 5px rgba(94, 91, 91, 0.25),
  0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset;

    border-radius: 5px;
    padding: 5px;
}
.totalAmbulance{
    color: white;
    margin: 25px !important;
}
.select{
        min-width: 330px !important;
        background: #F1F3F6;
        box-shadow: inset 0px -4px 4px #FFFFFF, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-top: 3% !important;
        height:40px !important;
        max-height: 100px !important;
        /* padding-left:15px !important */

       
}
.selectOption{
    min-width: 330px !important;
    background: #F1F3F6;
    box-shadow: inset 0px -4px 4px #FFFFFF, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 3% !important;
   height: 40px !important;
}


.label{
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 2%;
    display: inline-block;
}
.Amblabel{
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin-top: 2%;
    /* display: inline-block; */
    text-align: center;
}
.bookBtn{
    background: #129547 !important;
    color: white !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px !important;
    margin: 4% !important;
    width: 25% ;
}

.textSelect div{
    padding: 10px !important;
    min-width: 250px !important;
    background: #F1F3F6;
    box-shadow: inset 0px -4px 4px #FFFFFF, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 3% !important;
}
.customerLogin{
    background-color: #129547 !important;
    color: white !important;
    padding: 8px 30px !important;
}
.userCustomerLogin{
    background-color: #129547 !important;
    color: white !important;
    padding: 8px 30px !important;
    border-radius: 100px !important;
    width: 100% !important;
    height: 50px;
}
.userRegister{
    color: #FFFFFF;
}
.registerText{
    color: #E11F26;
    font-weight: 700;
    text-decoration-line: underline;
    cursor: pointer;
}
.userRegisterText{
    color: #FFFFFF;
    font-weight: 700;
    cursor: pointer;
}
.signText{
    color: #129547;
    font-weight: 700;
    text-decoration-line: underline;    
    cursor: pointer;
}
.textArea{
    background: #F1F3F6;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 4px #FFFFFF;
    border-radius: 5px;
    border: 0;
    -webkit-text-fill-color:white !important

}
.textArea:focus-visible {
   border: 0;
  }
  .otpText input{
    background: #F1F3F6;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 4px #FFFFFF;
    border-radius: 5px;
    border: 0;
    height: 50px !important;
    width: 50px !important;
    outline: 0;
  }
  .userOtpText input{
    background: #22272B;
    font-size: 30px;
    box-shadow: 0px 0px 7.5px 5px rgba(94, 91, 91, 0.25), 0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset;
    border-radius: 100px;
    color:#129547;
    border: 0;
    height: 50px !important;
    width: 50px !important;
    outline: 0;
  }
  .bottomImg{
    margin-top: 2% !important;
  }
  .text_field .MuiInputBase-fullWidth{
    height: 40px !important;
   }
   .userTextField .MuiInputBase-fullWidth{
    height: 40px !important;
   }
  .gpsflex{
    display: flex;
    align-items: center;
  }

.error{
    color:#E11F26
}
.responsiveheader{
    display: none !important;
}
.UserTitleName{
    font-size: 28px !important;
    color:white;
    text-align: center;
    font-weight: 700 !important;
}
.userFormLabel{
    color: white !important;
}
.userCloseIconPosition{
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
    color: white;

}  
.userYesButton{
    background-color: #129547 !important;
    color: white !important;
    padding: 8px 28px !important;
    border-radius: 50px !important;
}  
.userNoButton {
    background-color: #E11F26 !important;
    color: white !important;
    padding: 8px 28px !important;
    border-radius: 50px !important;
}
.userSavedAddressTitle{
    color: #129547;
    font-size: 16px !important;
    font-weight: 700 !important
}
.userSavedAddress{
    color: white;
    font-size: 14px !important;
    font-weight: 400 !important
}
::-webkit-scrollbar {
    width: 2px;
}
.UserwidthSet{
    max-width: 1710px !important;
}
@media only screen and (max-width: 767px) {
    /* .imgRespo{
        margin: auto;
        display: block;
    } */
    .login_text{
        padding: 15px;
    }
    .responsiveheader{
        display: block !important;
        background-color: #22272B !important;
    }
    .webHeader{
        display: none !important;
    }
    .textWhite1{
        padding: 0 20px !important;
    }
    .aboutLink{
        width: 100%;
        font-size: 12px !important;
        /* padding: 5px ; */
    }
    .aboutLink span{
        font-size: 12px;
    }
   .modalwidth{
    width: 350px !important;
    padding: 5% !important;
   }
   .profileheight{
    /* height: 550px !important; */
    width: 350px !important;
    padding: 5% !important;
   }
   .forgot_text{
    padding: 0 !important;
   }
   .ambulanceNameActive{
    color: #727272 !important;
   }
   .label{
    color: #727272 !important;
   }
.registerModal{
    height: 400px;
    width: 300px;
    overflow-y: scroll;
    
}
.formResponsive{
    width: 280px ;
}

  }
@media (min-width: 768px) and (max-width:1024px){
    .ambulanceNameActive{
        color: #727272 !important;
       }
       .label{
        color: #727272 !important;
       }

}
.navHome:hover{
    text-decoration: none !important;
}
.active .aboutLink{
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 8px 10px;
    white-space: nowrap;
    color: #E11F26;
}
.historyCard{
    background: #22272B;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
}
.cardActive{
    background: #22272B;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 7px #00000040 inset;

}
.profileModal div ul{
    width: 250px !important;
    background-color: #22272B;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

}
.profileNames{
    color: white;
}
.cancelBtn{
    background-color: #E11F26 !important;
    color: white !important;
    padding: 8px 28px !important;
}

.noDataText{
    color: white;
    font-size: 18px !important;
    font-weight: 600 !important;
    text-align: center;
    width: 100%;
    padding: 50px;
}