.aboutusData{
    font-size: 13px !important;
    line-height: 17px;
}
.dataContent{
    font-size: 13px !important;
    line-height: 17px;
    margin-bottom: 25px;
    color: white;
}
.bodyAlign{
    display: flex;
    flex-direction: column !important;
    height: 100vh;
}
.aboutBg{
    background-color: #22272B;
    flex-grow: 1;
}