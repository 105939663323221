.backgroundImage{
    background-image: url(../../../Assets/icons/background.png);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 690px;
}

.backgroundColor{
    background-color: #22272B !important;
}

.backImg{
    background-image: url(../../../Assets/icons/background.png);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 690px;
}

.listtrack{
    background: #22272B;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 5px;
    padding: 0 !important;
    cursor: pointer;
}

.menuItemTitle{
    color: #129547;
    font-weight: 600;
    font-size: 16px;
}

.userTitleName {
    font-size: 28px !important;
    color: #FFFFFF;
    text-align: center;
    font-weight: 700 !important;
}

.listtrack li {
    padding: 0 16px !important;
}

.itemtext span {
    font-size: 20px !important;
    color: #E11F26;
    font-weight: 700;
    padding-left: 10px;
}

.driverphone {
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px !important;
    width: 44px !important;
    height: 44px !important;
    padding: 10px !important;
}

.paymentbtn1 {
    background-color:#979899 !important;
    color: white !important;
    padding: 10px 30px !important;
}

.paymentbtn2 {
    background-color:#129547 !important;
    color: white !important;
    padding: 10px 30px !important;
}

.payComplete {
    background-color: #ff8c00 !important;
    color: white !important;
    padding: 10px 30px !important;
}

.linear {
    background-color: #D9D9D9 !important;
    height: 10px !important;
    border-radius: 10px !important;
}

.linear span {
   background-color: #129547 !important;
}

.linerbtn {
   background-color: #129547 !important;
   color: white !important;
   padding: 8px 20px !important;
   display: block !important;
   margin: auto !important;
   margin-top: 10% !important;
}

.linerbtn:hover{
   background-color: #129547;
   color: white;
}

.serachingText{
   text-align: center;
   margin-top: 5% !important;
   color: white ;
}

.acceptanceModal{
    background-color: #22272B !important;
}
.trackingHeight{
    height:450px;
}
.gpsIcon{
    color: #E11F26;
    background-color: #22272B;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px;
    margin: 2%;
    width: 32px  !important;
    height: 32px !important;
    padding: 2px;
    cursor: pointer;
}
.hospitalcard{
    width: 240px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F1F3F6 !important;
    /* box-shadow: -5px 5px 10px rgb(230 230 230 / 20%), 5px -5px 10px rgb(230 230 230 / 20%), -5px -5px 10px rgb(255 255 255 / 90%), 5px 5px 13px rgb(230 230 230 / 90%), inset 1px 1px 2px rgb(255 255 255 / 30%), inset -1px -1px 2px rgb(230 230 230 / 50%) !important; */
    border-radius: 10px !important;
}
.databg{
    background: #22272B;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 18px;
    padding: 20px;
}
.labelName{
    color: white;
}
.addressStyle{
    color: #E11F26;
    font-size: 16px;
    font-weight: 800;
    
}
.addressText{
    color: #129547;
    font-size: 16px;
    font-weight: 800;

}
/* ul{
    width: 350px !important;
    word-break: break-all;
} */
.homeText{
    color:'#129547 !important'
}
.textWord{
    white-space: pre-line;
    color: white !important;
}
.widthAdust{
    display: inline-block;
    width: 290px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.userWidthAdust{
    color: white;
    display: inline-block;
    width: 283px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 7px;
}

.bgColor{
    background: #22272B !important;
    color: white !important;
}

.userSelectOption{
    min-width: 330px !important;
    color: white !important;
    background: #22272B;
    box-shadow: 
    0px 0px 7.5px 5px #5E5B5B40,
    0px 0px 6.099999904632568px 7px #00000040 inset;
    border-radius: 5px !important;
    margin-top: 4% !important;
    margin-left: 10px;
    height: 40px !important;
    border-radius: 100px  !important;
    border: none !important;
}
.userSelectWidth{
    min-width: auto !important;
}
.preUserSelectOption{
    /* min-width: 330px !important; */
    color: white !important;
    background: #22272B;
    box-shadow: 
    0px 0px 7.5px 5px #5E5B5B40,
    0px 0px 6.099999904632568px 7px #00000040 inset;
    border-radius: 5px !important;
    margin-top: 4% !important;
    /* margin-left: 10px; */
    height: 40px !important;
    border-radius: 100px  !important;
    border: none !important;
}

.userSelectOption svg {
  color:white !important;  
}
.preUserSelectOption svg {
    color:white !important;  
}
.locationText{
    color: white !important;
}
.userSelectOption .Mui-disabled {
    -webkit-text-fill-color: white !important;
}
.userTextField .Mui-disabled {
    -webkit-text-fill-color: white !important;
}
.addressField .Mui-disabled {
    -webkit-text-fill-color: white !important;
}
.userConfirmLocationbtn{
    border-radius: 100px !important;
    background-color: #129547 !important;
    color: white !important;
    text-transform: capitalize !important;
    padding: 10px 20px !important;
    box-shadow: 0px 4px 4px 0px #00000040 !important;

}
.userSelectOption ul{
    background-color: #22272B !important;
    color: white !important;
}

.paperBg{
  background-color: #22272B !important;
  color: white !important;
}
.userDescriptionField fieldset {
    border: 0 !important;
    box-shadow: 0px 0px 7.5px 5px rgba(94, 91, 91, 0.25), 0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset;
    border-radius: 20px !important;
}

.userDescriptionField textarea{
    color: white !important;
}
.userBookButton{
    background: #129547 !important;
    color: white !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px !important;
    margin: 10% 0 !important;
    width: 70%;
    padding: 15px !important;
}

.userBuyButton{
    background: #129547 !important;
    color: white !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 100px !important;
    margin-top: 10% !important;
    width: 50%;
    padding: 15px !important;
}

.subscriptionText{
   color: white;
}

.orderList {
    color: white;
    margin-left: 24px;
    font-size: 14px;
    font-weight: 600;
}
.orderList li {
    margin-top: 10px;
}
.itemBoxShadow{
    box-shadow: 0px 0px 7.363013744354248px 4.908675670623779px rgba(94, 91, 91, 0.25);
    padding: 10px;
}
.bookingHeading{
    color: white;
}
.displayText{
    color: white;
}
.contactUsText{
    color: white;
    text-align: center;
    font-size: 18px;
}
.MuiDateCalendar-root{
    background-color: #22272B  !important;
}
.MuiPickersCalendarHeader-labelContainer {
    color: white !important;
}
.MuiPickersCalendarHeader-switchViewButton
{
    color: white !important;
}
.MuiPickersArrowSwitcher-button {
    color: white !important;
}
.MuiDayCalendar-root span{
    color: white !important;
}
.MuiDayCalendar-monthContainer button{
    color: white !important;
}
.MuiPickersDay-today  {
    border: 1px solid white !important;
}
.MuiPickersDay-root.Mui-selected {
    color: #fff;
    background-color: #129547 !important;
    font-weight: 500;
}
.MuiPickersYear-yearButton.Mui-selected {
    color: #fff;
    background-color: #129547 !important;
}
.MuiYearCalendar-root{
    color: white !important;
}
.MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
    color: rgba(255, 255, 255, 0.5) !important;
}
.buttonDisabled{
    background: rgba(255, 255, 255, 0.5) !important;
}
@media only screen and (max-width: 767px) {
    .ambulanceImg {
        width:100%;
        padding: 0 20px;
    }
    .aboutresponsive{
      margin-top: 10% !important;
      text-align: justify !important;
    }
    .backgroundImage{
        background-image: url(../../../Assets/icons/mobileview.png);
    }
    .formresponsive{
      margin: 0 20%;
    }
    .hospitalcard{
      width: auto ;
    }
    .footerresponsive{
     width: 285px;
    }
    .footerlogo{
      margin: auto;
      display: block;
    }
    .mapblock{
        display: block !important;
    }
    .acceptanceModal{
        width: 300px !important;
    }
    .mapmodal{
        width:350px !important
    }
    .confirmLocationbtn{
        margin-top: 20px !important;
    }
    .historypage{
        padding-left: 0 !important;
    }
    .labelName span{
        color: #979899;
    }
    .userForgotText{
        padding: 0 !important;
    }
    .savedInReponsive{
        height: 300px;
        overflow-y: auto;
    }
    .userSelectWidth {
        min-width: 330px !important;
    }
}
@media (min-width: 768px) and (max-width:1024px){
    .backgroundImage{
        background-image: url(../../../Assets/icons/ipadview.png);
        background-size: cover;
    }
    .formresponsive{
        margin-top: 8%;
    }
    .userSelectOption{
        min-width: 150px !important;
    }
}
.pac-container{
    z-index:999999!important
}
.MuiPaper-root{
    min-width: 0px !important;
} 
.MuiPaper-root ul{
    width: 400px !important;
    background-color: #22272B;
    color: white !important;
}
.selectOption .textWord{
    display: none;
}
.selectOption div{
    margin-top: 0 !important;
    justify-content: left;
}
.userSelectOption .textWord{
    display: none;
}
.userSelectOption div{
    margin-top: 0px !important;
    justify-content: left;
    color: white;
}
.userdatePicker div {
    margin-top: -6px !important;
    justify-content: left;
    color: white !important;
    padding: 0 12px !important; 
}
.userdatePicker fieldset {
    border: none !important;
}
.menuTitle{
    color: #E11F26;
    font-weight: 900 !important;
    margin-bottom: 10px;
}
.selectOption .menuTitle{
    color: black;
    font-weight: 400 !important;
    margin-bottom: 0px;
}
.userSelectOption .menuTitle{
    color:white ;
    font-weight: 400 !important;
    margin-bottom: 0px;
}
.listHeading{
    color: #BFBFBF;
    font-size: 18px;
    text-align: center;

}
.listText{
    color: white;
    text-align: center;
    word-break: break-all;
}
.listItemBorder{
    border-bottom: 1px solid #474747;
    padding-bottom: 20px;
}
.noDataFoundText{
    color: white;
    font-size: 20px;
    font-weight: 600;
    height: 140px;
    padding-top: 20px;
    text-align: center;
}