.headColor{
    color: black !important;
    font-weight: 700 !important;
    font-size: 13px !important;
}
.headerBg{
    background-color:#F6F9FC;
  
}

/* .tabel_cell{
    color: #979899;
    font-size: 13px !important;
} */

.price_base{
    font-size: 13px !important;
    font-weight: 700 !important;
    text-align: center !important;
}
.lableName{
    font-size: 14px !important;
    text-align: center !important;
}
.editBtn{
    background: #F1F3F6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px #FFFFFF;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

.bordericons{
    display: contents !important;
}
td{
 border-bottom:1px solid rgba(224, 224, 224, 1) !important;
}