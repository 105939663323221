.hospitalImg{
    box-shadow: -5px 5px 10px rgb(230 230 230 / 20%), 5px -5px 10px rgb(230 230 230 / 20%), -5px -5px 10px rgb(255 255 255 / 90%), 5px 5px 13px rgb(230 230 230 / 90%), inset 1px 1px 2px rgb(255 255 255 / 30%), inset -1px -1px 2px rgb(230 230 230 / 50%);
    border-radius: 10px;
    height: 122px;
    padding-top: 10px !important;
    display: flex;
    align-items: center;    
}
.pluscard{
    background-color: #EFEFEF;
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 10px;
    height: 140px !important;
    /* width: 214px !important; */
    margin-right: 20px !important;
    cursor: pointer;
    
}
.uploadlogo{
    box-shadow: -5px 5px 10px rgba(217, 219, 221, 0.2), 5px -5px 10px rgba(217, 219, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(217, 219, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(217, 219, 221, 0.5);
    border-radius: 5px;
    height: 150px;
    display: flex;
    justify-content: center;
}
.logowidth{
    width: 141px; 
    height: 91px;
}
.hospitalName{
   margin: 20px 10px 20px 10px ;
}
.hospitallogodeleteicon{
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5) ;
    width: 28px !important;
    height: 28px !important;
    padding: 4px;
    border-radius: 20px;
    text-align: center;
}
.rowname{
    width: 170px;
    word-break: break-all;
}
