.logo_card{
    width: fit-content;
    box-shadow: -5px 5px 10px rgba(217, 219, 221, 0.2), 5px -5px 10px rgba(217, 219, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(217, 219, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(217, 219, 221, 0.5) !important;
    border-radius: 100% !important;
    padding: 15px;
    margin-top: 2% !important;
}

.logo_img{
    margin: auto;
    display: block;
    background-color: #F1F3F6 !important;
}

.text_field{
  width:100%;
  margin: 4px 12px 4px 0px !important;
  border: none;
}
.userTextField{
  width:100%;
  margin: 4px 12px 4px 0px !important;
  border: none;

}
.userTextField div{
    height: 40px;
}
.text_field div{
  height: 40px;
}

.text_field input{
    padding: 0 10px;
    font-size: 14px !important;
}
.userTextField input{
    padding: 0 10px;
    font-size: 14px !important;
    color: white !important;
    background-color: #22272B !important;
    /* -webkit-text-fill-color: white !important; */
}
input:-webkit-autofill {
    height: 40px !important;
    border-radius: 100px !important;
}
.text_field fieldSet{
    border: 0 !important;
    box-shadow: inset 0px -4px 4px #FFFFFF, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.textField fieldset {
    border: 0 !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45) !important;
    border-radius: 5px !important;
    /* background-color: red !important; */
    color: white !important;
}

.userTextField fieldset {
    border: 0 !important;
    box-shadow: 0px 0px 7.5px 5px rgba(94, 91, 91, 0.25), 0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset;
    border-radius: 100px !important;
}
.userAddressField fieldSet {
    border: 0 !important;
    box-shadow: 0px 0px 7.5px 5px rgba(94, 91, 91, 0.25), 0px 0px 6.099999904632568px 7px rgba(0, 0, 0, 0.25) inset ;
    border-radius: 5px;
    -webkit-text-fill-color:white !important
}
.addressField fieldSet{
    border: 0 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px;
    -webkit-text-fill-color:white !important
}
.Mui-disabled{
/* -webkit-text-fill-color:white !important; */
box-shadow: none !important;
}
.userAddressField div{
    color: white !important;
}
.userProfileImg{
    color: white;
    object-fit: contain;
}
.userLocationIcon{
    color: #E11F26;
    background: #22272B;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 100px;
    width: 32px !important;
    height: 32px !important;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.textarea{
    background: #F1F3F6;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 4px #FFFFFF;
    border-radius: 5px;
    outline:0 !important;
}

.textarea:hover{
    border: 0 !important;
}

.login_form{
   box-shadow: -5px 5px 10px rgba(217, 219, 221, 0.2), 5px -5px 10px rgba(217, 219, 221, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(217, 219, 221, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(217, 219, 221, 0.5) !important;
    border-radius: 30px !important;
    margin: auto;
    width: 100%;
    background-color: #F1F3F6 !important;
}

._3t8tT{
    display: none !important;
}

.login_btn{
    padding: 0 4rem !important;
    font-size: 20px !important;
}

.parent_position{
    position: relative;
}

.eyeicon_position{
    position: absolute;
    right:1.5rem;
    top:2.3rem;
    color: #129547;
    cursor: pointer;
}

/* forgot_pasword.css */

.forgot_text{
    font-size: 1rem !important;
    padding: 0 7rem !important;
    color:black !important;
}
.userForgotText{
    font-size: 1rem !important;
    padding: 0 7rem !important;
    color:white !important;
}
.viewBox{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px #FFFFFF !important;
    border-radius: 5px !important;
    min-height: 40px;
    word-break: break-all;
    padding: 10px;
    font-size: 14px !important;
}
.viewBox fieldset {
    border: none;
}
.modalscroll{
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.modalscrollprofile{
    height: 450px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.modalscrollwidth{
    /* width:95% !important; */
    padding-right: 10px;
}
td{
    border-bottom:1px solid rgba(224, 224, 224, 1) !important;
}
.linktext{
    color: white !important;
    text-decoration: none;
}
.linktext:hover{
    text-decoration: none;
}
.walletcard{
    width: 240px;
    height: 140px;
    background: #FFFFFF !important;
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5) !important;
    border-radius: 10px !important;
}
.walletNum{
    font-size: 50px !important;
    color: #129547;
    font-weight: 800 !important;
}
.walletText{
    color: #979899;
    margin-left: 25px !important;
    font-weight: 600 !important;

}
.walletPrice{
  font-size: 20px !important;
  color: #129547;
  font-weight: 800 !important;
  padding-bottom: 20px !important;

}
.nativetag{
  background: #FFFFFF;
  box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5);
  border-radius: 5px;
}
.addressLine{
    height: 300px;
    border-left: 2px solid #E1E1E1;
  max-height: 300px; 
  overflow-y: auto;
  
}
.addressHorizontal{
  border-bottom: 2px solid #E1E1E1;
}
.noBookingAvailable{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37vh;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.locationImg{
    display: flex;
    align-items: center;
    justify-content: center;
}

