.adminLayout{
  height: 100vh;
}
.headerHeight{
  height: 12vh;
}
.bodyHeight{
  height: 88vh;
}
.widthSet{
  max-width: 1500px !important;
  height: 100%;
}
.listItem_pad{
  height: 99%;
  overflow-y: auto;
}
.outletBody{
  height: 100%;
}
.outletBodyHeading{
  height: 15%;
}
.outletBodyBody{
  height: 75%;
  overflow-y: auto;
}
.outletDashboardBody{
  height: 84%;
  overflow-y: auto;
}
.outletBodyTableContainer{
  height: 100%;
}
.outletBodyFooter{
  height: 10%;
}
.outLetHeight{
  height: 98%;
}
.dashboard_bg{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 6px;
}
.cardShadow{
    box-shadow: -5px 5px 10px rgba(230, 230, 230, 0.2), 5px -5px 10px rgba(230, 230, 230, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(230, 230, 230, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(230, 230, 230, 0.5) !important;
    border-radius: 10px !important;
}

.bordertd {
  text-align: center;
  font-weight: 900 !important;
}
.bordertd p {
  font-weight: 900;
  color: orange;
}
.flexitem{
  display: flex !important;
  justify-content: center;
}
.paginationAlign ul{
  justify-content: end;
  padding: 10px 0px;
}
.paginationAlign button.Mui-selected{
  background-color: #129547 !important;
  color: #ffffff;
}
.markerText{
  color: #E11F26;
  font-size: 16px;
  font-weight: 700;
}
.ambText{
  margin-top: 10px;
  color: black;
  font-size: 14px;
  font-weight: 400;
}
.gm-style-iw button {
  display:none !important;
}
.AdminitemBoxShadow{
  box-shadow: 0px 0px 7.363013744354248px 4.908675670623779px #5E5B5B40;
background-color: #22272B;
border-radius: 5px;
}
.AdminOrderList{
  height: 200px;
  color: white;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  
}
.planBackground{
  background: linear-gradient(95.47deg, #D66D0B -0.22%, #E9770C 17.32%, #FAC39C 53.92%, #FACAA8 71.98%, #F26E0F 86.86%, #F58B40 97.24%);
  border-radius: 5px;

}
.planName{
  font-size: 36px !important;
  color: white !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  
}
.planText{
  font-size: 28px !important;
  color: white !important;
  font-weight: 400 !important;
}
.no-amb{
  font-size: 20px;
  padding-top: 30px;
  text-align: center;
  font-weight: 700;
}