.header_box{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* img:hover{
    cursor: pointer;
} */
.notificationHeading{
    font-weight: 700 !important;
}
.notificationView{
    background-color: #129547 !important;
    font-size: 8px !important;
    color: white !important;
    height: 20px !important;
    font-weight: 600 !important
}
.menuModal ul{
    background-color: #E9ECEF !important;
}
.badgeIcon span{
  background-color: #E11F26 !important;
}
.notificationemp{
    font-size: 13px !important;
    color: black !important;
}
.notificationAdd{
    font-size: 12px !important;
    color: #979899 !important;
}
.notificationDate{
    font-size: 10px !important;
    color: #979899 !important;
}
.menuModal div{
    width: 400px !important;
}
.itemBorder{
    border-bottom:1px solid #E9ECEF !important;
}
.iconBorder{
    border-right: 1px solid #E9ECEF;
    margin-right: 15px !important;
    height: 30px !important;
}
.cursordefault{
    cursor: auto !important;
}
.cursordefault img{
    cursor: auto !important;
}